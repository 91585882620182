import ApolloClient, {
  IntrospectionFragmentMatcher,
  InMemoryCache
} from "apollo-boost";
import fetch from "isomorphic-fetch";
import { getActingPetId } from "./actingPet";
import fragmentTypes from "./fragmentTypes";

/**
 * If the Apollo req is unauthorized, result.error.networkError will be an instance of this class.
 */
export class UnauthorizedError extends Error {}

async function customFetch(...args: Parameters<typeof fetch>) {
  const response = await fetch(...args);
  if (response.status === 401) {
    throw new UnauthorizedError(`Unauthorized`);
  }
  return response;
}

const apiHost = process.env.GATSBY_API_HOST;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: fragmentTypes
});

const cache = new InMemoryCache({ fragmentMatcher });

const headers: any = {};
const actingPetId = getActingPetId();
if (actingPetId) {
  headers["X-Acting-Pet-Id"] = actingPetId;
}

export const client = new ApolloClient({
  uri: `${apiHost}/graphql`,
  fetch: customFetch,
  credentials: "include",
  cache,
  headers
});
