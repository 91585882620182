import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { client } from "./client";
import { Provider } from "react-redux";
import { store } from "./store";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorMessage: null
    };
  }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      errorMessage: error.stack
    };
  }

  componentDidCatch(error, _errorInfo) {
    console.error(error);
    window.analytics.track(`Internal Error`, { message: error.stack });
  }

  render() {
    if (this.state.hasError) {
      return (
        <>
          <div>Oops! Something went wrong.</div>
          {this.state.errorMessage && (
            <div>
              <pre>{this.state.errorMessage}</pre>
            </div>
          )}
        </>
      );
    }

    return this.props.children;
  }
}

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>
    <ApolloProvider client={client}>
      <ErrorBoundary>{element}</ErrorBoundary>
    </ApolloProvider>
  </Provider>
);
