import React, { ReactNode, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { useQuery } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import withLocation from "../utils/withLocation";
import { apiTypes } from "../lib/apiTypes";

interface PageLayoutProps {
  children: ReactNode;
}

const PageLayout = withLocation<PageLayoutProps>(({ children, location }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const { data: currentUserData, error: currentUserError } = useQuery<
    apiTypes.getCurrentUser
  >(
    gql`
      query getCurrentUser {
        currentUser {
          id
        }
      }
    `
  );

  const [trackedPath, setTrackedPath] = useState<string | null>(null);
  const [identified, setIdentified] = useState(false);
  useEffect(() => {
    if (currentUserData || currentUserError) {
      if (currentUserData?.currentUser?.id && !identified) {
        window.analytics.identify(currentUserData.currentUser.id);
        setIdentified(true);
      }
      if (location.pathname !== trackedPath) {
        window.analytics.page();
        setTrackedPath(location.pathname);
      }
    }
  }, [
    location.pathname,
    currentUserData,
    currentUserError,
    trackedPath,
    identified
  ]);

  return (
    <div className="container">
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
      </Helmet>
      {children}
    </div>
  );
});

export default PageLayout;
