import React from "react";
import { Location, NavigateFn, WindowLocation } from "@reach/router";
import queryString from "query-string";

// A newer version of @reach/router has hooks, should use those once I can get that in somehow.
// https://medium.com/@chrisfitkin/how-to-get-query-string-parameter-values-in-gatsby-f714161104f

interface AddedProps {
  location: WindowLocation;
  navigate: NavigateFn;
  search: queryString.ParsedQuery<string>;
}

const withLocation = <P extends {}>(
  ComponentToWrap: React.ComponentType<P & AddedProps>
) => (props: P) => (
  <Location>
    {({ location, navigate }) => (
      <ComponentToWrap
        {...props}
        location={location}
        navigate={navigate}
        search={location.search ? queryString.parse(location.search) : {}}
      />
    )}
  </Location>
);

export default withLocation;
