// THIS FILE AUTOMATICALLY GENERATED BY yarn download-schema. Do not modify.

const fragmentTypes = {
  __schema: {
    types: [
      {
        kind: "INTERFACE",
        name: "Card",
        possibleTypes: [
          {
            name: "SimpleCard"
          },
          {
            name: "WalkCard"
          },
          {
            name: "LabeledCard"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "FiFeedItem",
        possibleTypes: [
          {
            name: "FiFeedActivityItem"
          },
          {
            name: "FiFeedGenericNotificationItem"
          },
          {
            name: "FiFeedGoalStreakItem"
          },
          {
            name: "FiFeedRankingUpdateItem"
          },
          {
            name: "FiFeedPhotoLikedItem"
          },
          {
            name: "FiFeedPhotoAddedItem"
          },
          {
            name: "FiFeedPetFollowedItem"
          },
          {
            name: "FiFeedFollowedPetPhotoAddedItem"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "DiscoverFeedItem",
        possibleTypes: [
          {
            name: "DiscoverFeedPhotoItem"
          },
          {
            name: "DiscoverFeedArticleItem"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "ConnectionState",
        possibleTypes: [
          {
            name: "ConnectedToUser"
          },
          {
            name: "ConnectedToBase"
          },
          {
            name: "ConnectedToCellular"
          },
          {
            name: "ConnectedToWifi"
          },
          {
            name: "UnknownConnectivity"
          }
        ]
      },
      {
        kind: "UNION",
        name: "OnlineConnectionState",
        possibleTypes: [
          {
            name: "ConnectedToUser"
          },
          {
            name: "ConnectedToBase"
          },
          {
            name: "ConnectedToWifi"
          },
          {
            name: "ConnectedToCellular"
          }
        ]
      },
      {
        kind: "UNION",
        name: "GPSTestResult",
        possibleTypes: [
          {
            name: "GPSTestCompleteResult"
          },
          {
            name: "GPSTestConnectionStolenResult"
          }
        ]
      },
      {
        kind: "UNION",
        name: "AdminGPSTestResult",
        possibleTypes: [
          {
            name: "AdminGPSTestConclusiveResult"
          },
          {
            name: "AdminGPSTestTimeoutResult"
          },
          {
            name: "AdminGPSTestErrorResult"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "TEvent",
        possibleTypes: [
          {
            name: "TLocationReportEvent"
          },
          {
            name: "TMobilityReportEvent"
          },
          {
            name: "TActivityReportEvent"
          },
          {
            name: "TOperationParamsChangeEvent"
          },
          {
            name: "TNotificationSendEvent"
          },
          {
            name: "TAppLaunchEvent"
          },
          {
            name: "TCollapsedEvents"
          },
          {
            name: "TModemRegistrationFailuresEvent"
          },
          {
            name: "TDeviceRestartEvent"
          },
          {
            name: "TStatsChangedEvent"
          },
          {
            name: "TDeviceInfoEvent"
          },
          {
            name: "TConnectionHistoryEvent"
          },
          {
            name: "TFirmwareUpdateEvent"
          },
          {
            name: "TLedEvent"
          },
          {
            name: "TBaseLogEvent"
          },
          {
            name: "TModuleLogEvent"
          },
          {
            name: "TGenericLogEvent"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "PetCollarState",
        possibleTypes: [
          {
            name: "NeedsReportsState"
          },
          {
            name: "HasReportsState"
          }
        ]
      },
      {
        kind: "UNION",
        name: "CollarStatus",
        possibleTypes: [
          {
            name: "OnlineCollarStatus"
          },
          {
            name: "OfflineCollarStatus"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "OngoingActivity",
        possibleTypes: [
          {
            name: "OngoingRest"
          },
          {
            name: "OngoingWalk"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "Activity",
        possibleTypes: [
          {
            name: "Walk"
          },
          {
            name: "Rest"
          },
          {
            name: "Travel"
          },
          {
            name: "MissingReports"
          },
          {
            name: "Play"
          }
        ]
      },
      {
        kind: "UNION",
        name: "MapPath",
        possibleTypes: [
          {
            name: "MapMatchedPath"
          },
          {
            name: "UnmatchedPath"
          }
        ]
      },
      {
        kind: "UNION",
        name: "TokenValidity",
        possibleTypes: [
          {
            name: "InvalidToken"
          },
          {
            name: "ValidToken"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "BasePet",
        possibleTypes: [
          {
            name: "Pet"
          },
          {
            name: "StrangerPet"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "FeatureFlag",
        possibleTypes: [
          {
            name: "StringFeatureFlag"
          },
          {
            name: "NumberFeatureFlag"
          },
          {
            name: "BooleanFeatureFlag"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "BooleanSettingPlatformOverride",
        possibleTypes: [
          {
            name: "BooleanSettingForceOnPlatformOverride"
          },
          {
            name: "BooleanSettingForceOffPlatformOverride"
          },
          {
            name: "BooleanSettingMinVersionPlatformOverride"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "BooleanSetting",
        possibleTypes: [
          {
            name: "TrueBooleanSetting"
          },
          {
            name: "FalseBooleanSetting"
          },
          {
            name: "GroupsBooleanSetting"
          },
          {
            name: "UserIdsBooleanSetting"
          },
          {
            name: "PercentageOfUsersBooleanSetting"
          },
          {
            name: "RandomPercentageBooleanSetting"
          }
        ]
      },
      {
        kind: "UNION",
        name: "PaymentInfo",
        possibleTypes: [
          {
            name: "ObfuscatedCardInfo"
          },
          {
            name: "PayPalInfo"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "ChipContactInfoResponse",
        possibleTypes: [
          {
            name: "ChipContactHistoryEntry"
          },
          {
            name: "ChipTransfer"
          }
        ]
      },
      {
        kind: "INTERFACE",
        name: "BaseChip",
        possibleTypes: [
          {
            name: "AssignedChip"
          },
          {
            name: "StrangerChip"
          }
        ]
      },
      {
        kind: "UNION",
        name: "PhotoPoster",
        possibleTypes: [
          {
            name: "StrangerUser"
          },
          {
            name: "ExternalUser"
          }
        ]
      }
    ]
  }
};
export default fragmentTypes;
