import { createStore } from "redux";

export interface State {
  selectedCommunityPhoto: number;
}

const defaultState: State = {
  selectedCommunityPhoto: 0
};

export type Action = {
  type: "SetSelectedCommunityPhoto";
  payload: {
    index: number;
  };
};

export const setSelectedCommunityPhoto = (index: number): Action => ({
  type: "SetSelectedCommunityPhoto",
  payload: { index }
});

const reducer = (state: State = defaultState, action: Action) => {
  if (action.type === "SetSelectedCommunityPhoto") {
    return {
      selectedCommunityPhoto: action.payload.index
    };
  }
  return state;
};

export const store = createStore(reducer, defaultState);
